<template>
  <section class="blog-header blog-header--content">
    <GridLines :columns="[1, 6, 11, 12]" />
    <div class="wrapper">
      <div class="page--backbtn">
        <div class="horizontal-text">
          <router-link :to="backTo ? backTo : '/'" class="back-button">
            Back
          </router-link>
        </div>
      </div>
      <div class="blog-header--main">
        <slot></slot>
      </div>
      <div class="blog-header--secondary">
        <ul v-if="categories.length > 0" class="category-list">
          <li v-for="(category, id) in categories" :key="id">
            <router-link
              :to="{
                name: 'blog-category',
                params: { category: category.slug },
              }"
              class="text-link"
            >
              {{ category.name }}
            </router-link>
          </li>
        </ul>
        <ul v-else class="category-list loading">
          <li v-for="i in expectedCategories" :key="i">
            {{ '█'.repeat(_random('6', '10')) }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import GridLines from '@/components/GridLines.vue';

export default {
  name: 'BlogHeader',
  components: {
    GridLines,
  },
  computed: {
    categories() {
      return this.$store.getters.allCategories;
    },
  },
  props: {
    backTo: {
      type: String,
      default: '/',
    },
  },
  methods: {},
  data() {
    return {
      expectedCategories: 10,
    };
  },
};
</script>
