<template>
  <main id="service">
    <kinesis-container
      class="page page--subpage page--hero"
      style="background-color: #0e92ae"
      data-brightness="dark"
      event="scroll"
    >
      <kinesis-element :strength="-100" axis="y" class="hero__image">
        <img
          :src="require('../assets/heros/hero-services.webp')"
          alt="Services"
        />
      </kinesis-element>
      <div class="wrapper">
        <div class="hero__content">
          <h1>Services</h1>
        </div>
      </div>
    </kinesis-container>

    <PageBlock
      class="services"
      block-title="BackBtn"
      data-brightness="light"
      :grid="[1, 5, 11, 12]"
      :backTo="'/#about-us'"
    >
      <article
        v-for="(service, index) in servicesList"
        :key="index"
        role="list"
      >
        <div class="services--image">
          <router-link
            :to="{ name: 'service', params: { slug: service.slug } }"
          >
            <img
              :src="require(`../assets/services/service-${service.slug}.png`)"
              :alt="service.title"
            />
          </router-link>
        </div>
        <div class="services--content">
          <h1>
            <router-link
              :to="{ name: 'service', params: { slug: service.slug } }"
            >
              {{ service.title }}
            </router-link>
          </h1>
          <p>{{ service.excerpt }}</p>

          <router-link
            class="button button--outline"
            :to="{ name: 'service', params: { slug: service.slug } }"
          >
            Read More
          </router-link>
        </div>
      </article>
      <template v-slot:end>
        <div class="gallery--wrapper">
          <figure class="gallery gallery--fit gallery--video">
            <GridLines :columns="[1, 2, 10, 11, 12]" />
            <div class="wrapper">
              <div class="inner-wrapper" style="padding-top: 50px">
                <div class="responsive-iframe">
                  <iframe
                    width="1280"
                    height="720"
                    :src="`${parseYouTubeURL('https://youtu.be/V90zH1XKgkk')}?rel=0&amp;showinfo=0`"
                    frameborder="0"
                    allowfullscreen=""
                  ></iframe>
                </div>
              </div>
            </div>
          </figure>
        </div>
      </template>
    </PageBlock>
  </main>
</template>

<script>
import services from '@/assets/services.yaml';
import PageBlock from '@/components/PageBlock.vue';
import GridLines from '@/components/GridLines.vue';

export default {
  name: 'ServicesList',
  components: {
    PageBlock,
    GridLines,
  },
  computed: {
    servicesList() {
      return this._sortBy(
        this._map(services, (s, slug) => {
          s.slug = slug;
          return s;
        }),
        'position'
      );
    },
  },
  data() {
    return {
      services: services || {},
    };
  },
  methods: {
    parseYouTubeURL(url) {
      const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11
        ? `https://www.youtube.com/embed/${match[2]}`
        : false;
    },
  },
  metaInfo() {
    return {
      title: 'Agency Services',
      meta: [
        {
          name: 'description',
          content:
            'The success of your marketing efforts depends on your trust in us to provide a fully integrated plan and executional excellence',
        },
        { property: 'og:title', content: `Agency Services : Octopus Ink` },
        { property: 'og:site_name', content: 'Octopus Ink' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://octopusink.ca/services` },
        // { property: 'og:image', content: 'https://octopusink.ca' + require(`../assets/services/service-${this.service.slug}.png`) },
        {
          property: 'og:description',
          content:
            'The success of your marketing efforts depends on your trust in us to provide a fully integrated plan and executional excellence',
        },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: `https://octopusink.ca/services` },
        { name: 'twitter:title', content: `Agency Services : Octopus Ink` },
        {
          name: 'twitter:description',
          content:
            'The success of your marketing efforts depends on your trust in us to provide a fully integrated plan and executional excellence',
        },

        // { name: 'twitter:image:src', content: 'https://octopusink.ca' + require(`../assets/services/service-${this.service.slug}.png`) },

        { itemprop: 'name', content: `Agency Services : Octopus Ink` },
        {
          itemprop: 'description',
          content:
            'The success of your marketing efforts depends on your trust in us to provide a fully integrated plan and executional excellence',
        },
        // { itemprop: 'image', content: 'https://octopusink.ca' + require(`../assets/services/service-${this.service.slug}.png`) }
      ],
      link: [{ rel: 'canonical', href: `https://octopusink.ca/services` }],
    };
  },
};
</script>
