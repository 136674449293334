<template>
  <figure :class="`gallery--wide gallery--${item.youtube ? 'video' : 'image'}`">
    <h3 v-if="item.title" class="wrapper">{{ item.title }}</h3>

    <img
      :src="require(`../assets/case-study/${item.image}`)"
      :alt="`${item.alt}`"
      v-if="item.image"
    />
    <div v-else-if="item.youtube" class="responsive-iframe">
      <iframe
        width="1280"
        height="720"
        :src="`${parseYouTubeURL(item.youtube)}?rel=0&amp;showinfo=0&amp;showcontrols=0`"
        frameborder="0"
        allowfullscreen=""
      ></iframe>
    </div>
  </figure>
</template>

<script>
export default {
  name: 'GalleryWide',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    parseYouTubeURL(url) {
      const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11
        ? `https://www.youtube.com/embed/${match[2]}`
        : false;
    },
  },
};
</script>
