<template>
  <div class="grid-lines">
    <div
      class="grid-line"
      v-for="(col, i) in columns"
      :class="'grid-width--' + columnWidth(col, i)"
      v-bind:key="i"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'GridLines',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    columnWidth(col, i) {
      const previousCol = this.columns[i - 1] || 0;
      return col - previousCol || 1;
    },
  },
};
</script>
