<template>
  <header class="banner">
    <div class="wrapper">
      <router-link to="/">
        <inline-svg
          :src="require('../assets/logo-octopusink.svg')"
          class="brand"
          fill="#fff"
        />
      </router-link>

      <button @click="openMenu" class="nav-toggle">
        <v-burger
          type="elastic"
          :active="open"
          :burgerStyle="burgerStyle"
          aria-label="Toggle Navigation Menu"
          :aria-expanded="open ? 'true' : 'false'"
        ></v-burger>
      </button>
    </div>
  </header>
</template>

<script>
import { VBurger } from 'vue-burger';
export default {
  name: 'SiteHeader',
  component: {
    VBurger,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      burgerStyle: {
        '--padding': '0px',
        '--layer-bg-color': '#ffffff',
      },
    };
  },
  methods: {
    openMenu() {
      this.$emit('open-menu', !this.open);
    },
  },
};
</script>
