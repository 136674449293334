<template>
  <PageBlock :block-title="blockTitle" :block-columns="blockColumns">
    <slot></slot>
    <template v-slot:secondary>
      <div class="anim">
        <p class="column-label">
          {{ blockColumns[1] }}
        </p>
        <slot name="secondary"></slot>
        <ul class="services-list" role="list">
          <li v-for="(service, index) in servicesList" :key="index">
            <router-link
              :to="{ name: 'service', params: { slug: service.slug } }"
              role="button"
              :aria-label="`About ${service.title} service`"
            >
              {{ service.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </template>
  </PageBlock>
</template>

<script>
import PageBlock from '@/components/PageBlock.vue';
import services from '@/assets/services.yaml';

export default {
  name: 'AboutUsBlock',
  components: {
    PageBlock,
  },
  computed: {
    servicesList() {
      return this._sortBy(
        this._map(services, (s, slug) => {
          s.slug = slug;
          return s;
        }),
        'position'
      );
    },
  },
  props: {
    blockTitle: {
      type: String,
      default: 'The Team',
    },
    blockColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      services: services || {},
      sliderIndex: 0,
    };
  },
};
</script>
