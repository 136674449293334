import HomePage from '@/views/HomePage';
import ServicesList from '@/views/ServicesList';
import SingleService from '@/views/SingleService';
import OurWorkPage from '@/views/OurWorkPage';
import CaseStudy from '@/views/CaseStudy';
import BlogIndex from '@/views/BlogIndex';
import BlogPost from '@/views/BlogPost';
import MarketingCalculator from '@/views/MarketingCalculator';
import PrivacyPolicy from '@/views/PrivacyPolicy';
import PageNotFound from '@/views/PageNotFound';

export default [
  // Home
  { path: '/', component: HomePage },

  // Services
  { path: '/services', name: 'services', component: ServicesList },
  { path: '/services/:slug', name: 'service', component: SingleService },

  // Our Work
  { path: '/our-work', name: 'our-works', component: OurWorkPage },
  { path: '/our-work/:slug', name: 'our-work', component: CaseStudy },

  // Blog index
  {
    path: '/blog',
    name: 'blog',
    meta: { banner: 'light', async: true },
    component: BlogIndex,
  },
  {
    path: '/blog/page/:paged',
    name: 'blog-paged',
    meta: { banner: 'light', async: true },
    component: BlogIndex,
  },

  // Blog category
  {
    path: '/blog/category/:category',
    name: 'blog-category',
    meta: { banner: 'light', async: true },
    component: BlogIndex,
  },
  {
    path: '/blog/category/:category/page/:paged',
    name: 'blog-category-paged',
    meta: { banner: 'light', async: true },
    component: BlogIndex,
  },

  // Blog author
  {
    path: '/blog/author/:author',
    name: 'blog-author',
    meta: { banner: 'light', async: true },
    component: BlogIndex,
  },
  {
    path: '/blog/author/:author/page/:paged',
    name: 'blog-author-paged',
    meta: { banner: 'light', async: true },
    component: BlogIndex,
  },

  // Blog single
  {
    path: '/blog/:slug/',
    name: 'blog-single',
    component: BlogPost,
    meta: { async: true },
  },

  // Pages
  {
    path: '/marketing-spend-calculator',
    name: 'marketing-spend-calculator',
    component: MarketingCalculator,
  },
  {
    path: '/privacy-policy',
    meta: { banner: 'light' },
    component: PrivacyPolicy,
  },

  // 404
  { path: '/404', component: PageNotFound },
  { path: '*', component: PageNotFound },
];
