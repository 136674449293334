<template>
  <article
    class="blog-grid--post"
    :class="{ 'blog-grid--post__small': !post.custom_fields.large_image }"
  >
    <div class="blog-grid--post-inner">
      <router-link :to="{ name: 'blog-single', params: { slug: post.slug } }">
        <img
          :src="post.post_thumbnail[imageSize][0]"
          :width="post.post_thumbnail[imageSize][1]"
          :height="post.post_thumbnail[imageSize][2]"
          :alt="post.title.rendered"
        />
      </router-link>
      <div class="blog-grid--post-content">
        <h2>
          <router-link
            :to="{ name: 'blog-single', params: { slug: post.slug } }"
          >
            {{ post.title.rendered }}
          </router-link>
        </h2>
        <PostMeta :post="post" />
        <div v-html="post.excerpt.rendered" class="excerpt"></div>
      </div>
    </div>
  </article>
</template>

<script>
import PostMeta from '@/components/PostMeta.vue';

export default {
  components: {
    PostMeta,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageSize() {
      return this.post.custom_fields.large_image ? 'post-large' : 'post';
    },
  },
};
</script>
