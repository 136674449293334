<template>
  <figure :class="`gallery--wide gallery--wide-video gallery--video`">
    <h3 v-if="item.title" class="wrapper">{{ item.title }}</h3>

    <div class="responsive-iframe">
      <video
        :poster="require(`../assets/case-study/${item.poster}`)"
        :noloop="item.settings && item.settings.includes('noloop')"
        :loop="!item.settings || !item.settings.includes('noloop')"
        muted
        ref="v"
      >
        <source
          :src="require(`../assets/case-study/${item.video}`)"
          type="video/mp4"
        />
        <img
          :src="require(`../assets/case-study/${item.fallback}`)"
          :alt="`${item.alt}`"
        />
      </video>
    </div>
  </figure>
</template>

<script>
export default {
  name: 'GalleryWideVideo',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      played: false,
    };
  },
  methods: {
    handleScroll() {
      if (!this.$refs.v) return;
      const containerOffset = this.$refs.v.getBoundingClientRect().top;
      const threeQuartersViewport = (window.innerHeight / 4) * 3;

      // this reveals the content when it's in the viewport
      // (half the viewport height is the trigger point)
      if (0 >= containerOffset - threeQuartersViewport && !this.played) {
        setTimeout(() => {
          this.$refs.v.play();
          this.played = true;
        }, 500);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      document.addEventListener(
        'scroll',
        this._debounce(this.handleScroll, 50)
      );
    }, 1000);

    if (!this.$route.meta.async) {
      // console.log('render done')
      document.dispatchEvent(new Event('render-event'));
    }
  },
};
</script>
