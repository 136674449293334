<template>
  <span class="responsive-image" :class="{ loading: !loaded }">
    <img
      v-if="!image"
      src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
    />
    <img v-else-if="!loaded" :src="image['medium'][0]" />
    <img
      v-else-if="!lazy"
      :src="image['hero-max'][0]"
      :srcset="image.srcset"
      :sizes="image.sizes"
      :alt="alt"
    />
    <v-lazy-image
      v-else
      :src="image['hero-max'][0]"
      :srcset="image.srcset"
      :src-placeholder="image.medium[0]"
      :sizes="image.sizes"
      :alt="alt"
    />
  </span>
</template>

<script>
import vLazyImage from 'v-lazy-image';

export default {
  components: {
    vLazyImage,
  },
  props: {
    name: {
      type: String,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
      default: '',
    },
    prefetchedImage: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    if (this.prefetchedImage) {
      this.image = this.prefetchedImage;
      this.imageLoader(this.image);
      return;
    }
    this.$store
      .dispatch('getMedia', this.name)
      .then(() => {
        this.image = this.$store.getters.thisMedia(this.name);
        this.imageLoader(this.image);
      })
      .catch((err) => {
        this.error = err;
      });
  },
  watch: {
    prefetchedImage(val) {
      this.image = val;
      this.imageLoader(this.image);
    },
    name(val) {
      this.$store
        .dispatch('getMedia', val)
        .then(() => {
          this.image = this.$store.getters.thisMedia(val);
          this.imageLoader(this.image);
        })
        .catch((err) => {
          this.error = err;
        });
    },
  },
  data() {
    return {
      image: null,
      error: null,
      loaded: false,
    };
  },
  methods: {
    imageLoader(image) {
      const imageLoader = new Image();
      imageLoader.size = image.sizes;
      imageLoader.srcset = image.srcset;
      // console.log(image);
      imageLoader.src = image['hero-max'][0];
      imageLoader.onload = () => {
        this.loaded = true;
      };
    },
  },
};
</script>
