<template>
  <main id="our-work">
    <kinesis-container
      class="page page--subpage page--hero"
      style="background-color: #8b608d"
      data-brightness="dark"
      event="scroll"
    >
      <kinesis-element :strength="-100" axis="y" class="hero__image">
        <img
          :src="require('../assets/heros/slide-1-heart.webp')"
          alt="Our Work"
        />
      </kinesis-element>
      <div class="wrapper">
        <div class="hero__content">
          <h1>Our Work</h1>
        </div>
      </div>
    </kinesis-container>

    <WorkBlock
      class="work"
      block-title="BackBtn"
      :block-columns="['Our Work', 'Case Studies']"
    >
      <h2>We believe that our work needs to drive your sales.</h2>
      <p>
        We're nothing without the amazing partnerships we have with our clients.
        Please take a scroll through just a few examples of the work we've done
        to drive leads, trial, clicks, traffic and conversions.
      </p>
    </WorkBlock>
  </main>
</template>

<script>
import WorkBlock from '@/components/WorkBlock.vue';

export default {
  name: 'HomePage',
  components: {
    WorkBlock,
  },
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: 'Our Work',
      meta: [
        {
          name: 'description',
          content: 'We believe that our work needs to drive your sales.',
        },
        { property: 'og:title', content: `Our Work : Octopus Ink` },
        { property: 'og:site_name', content: 'Octopus Ink' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://octopusink.ca/our-work` },
        // { property: 'og:image', content: 'https://octopusink.ca' + require(`../assets/our-work/service-${this.service.slug}.png`) },
        {
          property: 'og:description',
          content: 'We believe that our work needs to drive your sales.',
        },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: `https://octopusink.ca/our-work` },
        { name: 'twitter:title', content: `Our Work : Octopus Ink` },
        {
          name: 'twitter:description',
          content: 'We believe that our work needs to drive your sales.',
        },

        // { name: 'twitter:image:src', content: 'https://octopusink.ca' + require(`../assets/our-work/service-${this.service.slug}.png`) },

        { itemprop: 'name', content: `Our Work : Octopus Ink` },
        {
          itemprop: 'description',
          content: 'We believe that our work needs to drive your sales.',
        },
        // { itemprop: 'image', content: 'https://octopusink.ca' + require(`../assets/our-work/service-${this.service.slug}.png`) }
      ],
      link: [{ rel: 'canonical', href: `https://octopusink.ca/our-work` }],
    };
  },
};
</script>
