<template>
  <main id="blog">
    <div class="alert" data-brightness="light" v-if="error">
      <div class="wrapper">
        <p>{{ error }}</p>
      </div>
    </div>
    <BlogHeader data-brightness="light" :backTo="backTo">
      <h1 v-if="thisCategory">{{ thisCategory.name }}</h1>
      <h1 v-else-if="thisAuthor">
        Posts by <strong>{{ thisAuthor.name }}</strong>
      </h1>
      <h1 v-else-if="isBlogHome">
        OctoBlog: <strong>Ink</strong>lings & <strong>Ink</strong>sights
      </h1>

      <p v-if="thisCategory">{{ thisCategory.description }}</p>
      <p v-else-if="isBlogHome || (thisAuthor && isBlogAuthor)">
        We love staying up to speed on the latest and greatest in the industry.
        Our team believes in learning from example, examining success and
        celebrating the knowledge gained from trying new things.
      </p>
    </BlogHeader>

    <section class="blog-grid" data-brightness="light" v-if="posts">
      <div class="wrapper">
        <div
          v-if="posts.length > 0"
          class="posts"
          v-masonry
          id="posts"
          item-selector=".blog-grid--post"
          transition-duration="0s"
        >
          <BlogThumbnail
            class="blog-grid--post"
            v-masonry-tile
            v-for="post in posts"
            :key="post.id"
            :post="post"
          />
        </div>
        <div v-else class="posts loading">
          <div v-for="i in expectedPosts" :key="i" class="blog-grid--post">
            <div class="blog-grid--post--image"></div>
            <div class="blog-grid--post--content">
              <h2 class="loading-title">███████████████ ████</h2>
              <div class="loading-excerpt">
                ███ ████████ ████████ █████████ ██████ █████ █ ████ ████████ ███
                █████
              </div>
            </div>
          </div>
        </div>

        <Pagination
          v-if="numPages > 1"
          v-model="paged"
          :per-page="perPage"
          :records="numPosts"
          :options="paginationOptions"
        />
      </div>
    </section>
  </main>
</template>

<script>
import BlogHeader from '@/components/BlogHeader.vue';
import BlogThumbnail from '@/components/BlogThumbnail.vue';
import Pagination from 'vue-pagination-2';
import PaginationTemplate from '@/components/PaginationTemplate.vue';

export default {
  name: 'BlogIndex',
  components: {
    BlogHeader,
    BlogThumbnail,
    Pagination,
  },
  watch: {
    $route() {
      this.getPosts();
    },
  },
  computed: {
    isBlogHome() {
      return this.$route.name === 'blog' || this.$route.name === 'blog-paged';
    },
    isBlogAuthor() {
      return (
        this.$route.name === 'blog-author' ||
        this.$route.name === 'blog-author-paged'
      );
    },
    posts() {
      return this.$store.getters.allPosts;
    },
    thisCategory() {
      if (!this.$route.params.category) return false;
      return this.$store.getters.thisCategory(this.$route.params.category);
    },
    thisAuthor() {
      if (!this.$route.params.author) return false;
      return this.$store.getters.thisAuthor(this.$route.params.author);
    },
    numPosts() {
      return this.$store.getters.numPosts;
    },
    numPages() {
      return this.$store.getters.numPages;
    },
    paged: {
      get() {
        return this.$route.params.paged
          ? parseInt(this.$route.params.paged)
          : 1;
      },
      set(value) {
        if (this.paged === value) return;
        this.switchPage(value);
      },
    },
    backTo() {
      if (this.$route.params.category || this.$route.params.author) {
        return '/blog';
      }

      if (this.$route.params.paged) {
        let parts = this.$route.path.split('/page');
        return parts[0];
      }

      return '/';
    },
  },
  mounted() {
    this.$store.dispatch('getCategories').then(() => {
      this.$store
        .dispatch('getAuthors')
        .then(() => {
          this.getPosts();
        })
        .then(() => {
          setTimeout(() => {
            if (typeof this.$redrawVueMasonry === 'function') {
              this.$redrawVueMasonry('posts');
            }
          }, 1000);
        })
        .catch((err) => {
          this.error = err;
        });
    });
  },
  data() {
    return {
      loading: true,
      perPage: 12,
      expectedPosts: 4,
      error: null,
      paginationOptions: {
        template: PaginationTemplate,
        padded: true,
        chunksNavigation: 'scroll',
        texts: {
          count: '|',
          prevPage: 'Newer',
          nextPage: 'Older',
        },
      },
    };
  },
  methods: {
    cleanHead() {
      const yoastHeadStart =
        '<!-- This site is optimized with the Yoast SEO plugin';
      const yoastHeadEnd = '<!-- / Yoast SEO plugin. -->';
      const headContent = document.head.innerHTML;
      const startIndex = headContent.indexOf(yoastHeadStart);
      if (startIndex > -1) {
        const endIndex =
          headContent.indexOf(yoastHeadEnd) + yoastHeadEnd.length;
        const newHeadContent =
          headContent.substring(0, startIndex) +
          headContent.substring(endIndex);
        document.head.innerHTML = newHeadContent;
      }
      if (document.head.querySelector('title')) {
        document.head.querySelector('title').remove();
      }
    },
    switchPage(page) {
      const pagedRoute = {
        name: this.$route.name,
        params: this.$route.params,
      };
      pagedRoute.params.paged = page;

      if (page === 1) {
        if (pagedRoute.name.indexOf('-paged') > -1) {
          let parts = pagedRoute.name.split('-paged');
          pagedRoute.name = parts[0];
        }
        return this.$router.push(pagedRoute);
      }

      if (page > 1 && this.$route.name.indexOf('-paged') === -1) {
        pagedRoute.name = this.$route.name + '-paged';
      }
      this.$router.push(pagedRoute);
    },
    getPosts() {
      this.$store
        .dispatch('getPosts', {
          per_page: this.perPage,
          categories: this.thisCategory ? this.thisCategory.id : null,
          author: this.$route.params.author ? this.thisAuthor.id : null,
          page: this.$route.params.paged ? this.paged : 1,
        })
        .then(() => {
          this.loading = false;
          this.$nextTick(() => {
            setTimeout(() => {
              // console.log('render done')
              document.dispatchEvent(new Event('render-event'));
            }, 1000);
          });
          if (this.$store.state.error) {
            this.error = this.$store.state.error;
          }

          this.cleanHead();
          if (this.thisCategory.yoast_head) {
            document.head.innerHTML =
              this.thisCategory.yoast_head + document.head.innerHTML;
          } else if (this.thisAuthor.yoast_head) {
            document.head.innerHTML =
              this.thisAuthor.yoast_head + document.head.innerHTML;
          }
        })
        .catch((err) => {
          this.error = err;
          this.$nextTick(() => {
            console.log('render done with error');
            document.dispatchEvent(new Event('render-event'));
          });
          if (this.$store.state.error) {
            this.error = this.$store.state.error;
          }
        });
    },
  },
  metaInfo() {
    if (this.thisCategory || this.thisAuthor) {
      return {
        title: 'OctoBlog: Inklings & Inksights',
      };
    }
    return {
      title: 'OctoBlog: Inklings & Inksights',
      meta: [
        {
          name: 'description',
          content:
            'We love staying up to speed on the latest and greatest in the industry. Our team believes in learning from example, examining success and celebrating the knowledge gained from trying new things.',
        },
        { property: 'og:title', content: 'OctoBlog: Inklings & Inksights' },
        { property: 'og:site_name', content: 'Octopus Ink' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://octopusink.ca/blog' },
        // { property: 'og:image', content: 'https://octopusink.ca' + require('@/assets/share-image.jpg') },
        {
          property: 'og:description',
          content:
            'We love staying up to speed on the latest and greatest in the industry. Our team believes in learning from example, examining success and celebrating the knowledge gained from trying new things.',
        },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: 'https://octopusink.ca/' },
        {
          name: 'twitter:title',
          content: 'OctoBlog: Inklings & Inksights : Octopus Ink',
        },
        {
          name: 'twitter:description',
          content:
            'We love staying up to speed on the latest and greatest in the industry. Our team believes in learning from example, examining success and celebrating the knowledge gained from trying new things.',
        },

        // { name: 'twitter:image:src', content: 'https://octopusink.ca' + require('@/assets/share-image.jpg') },

        {
          itemprop: 'name',
          content: 'OctoBlog: Inklings & Inksights : Octopus Ink',
        },
        {
          itemprop: 'description',
          content:
            'We love staying up to speed on the latest and greatest in the industry. Our team believes in learning from example, examining success and celebrating the knowledge gained from trying new things.',
        },
        // { itemprop: 'image', content: 'https://octopusink.ca' + require('@/assets/share-image.jpg') }
      ],
      link: [{ rel: 'canonical', href: 'https://octopusink.ca/' }],
    };
  },
};
</script>
