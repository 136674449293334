<template>
  <div class="social-share">
    <h4>Share</h4>
    <ul class="social-share--list">
      <li v-for="network in networks" :key="network.name">
        <a
          :href="network.href"
          target="_blank"
          class="social-share--link"
          :class="`social-share--link__${network.name.toLowerCase()}`"
        >
          <span class="sr-only">Share on {{ network.name }}</span>
          <FontAwesomeIcon
            :icon="network.icon"
            :color="network.color"
          ></FontAwesomeIcon>
        </a>
      </li>
    </ul>
    <div id="fb-root"></div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faFacebookSquare,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

function hexToRgb(hex, alpha = 1) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})`
    : null;
}

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    link() {
      if (this.post.link) {
        return this.post.link;
      } else {
        return `https://octopusink.ca/our-work/${this.post.slug}`;
      }
    },
    title() {
      if (this.post.title.rendered) {
        return encodeURIComponent(this.post.title.rendered);
      } else {
        if (this.post.service && typeof this.post.service === 'object') {
          return encodeURIComponent(
            `${this.post.title} : ${this.post.service.join(', ')}`
          );
        } else {
          return encodeURIComponent(this.post.title);
        }
      }
    },
    networks() {
      return [
        {
          name: 'Facebook',
          href: `https://www.facebook.com/sharer/sharer.php?u=${this.link}`,
          icon: faFacebookSquare,
          color: '#3B5998',
          color_bg: hexToRgb('#3B5998', '0.3'),
        },
        {
          name: 'LinkedIn',
          icon: faLinkedin,
          href: `https://www.linkedin.com/shareArticle?mini=true&url=${this.link}&title=${this.title}`,
          color: '#0E76A8',
          color_bg: hexToRgb('#0E76A8', '0.3'),
        },
      ];
    },
  },
  data() {
    return {};
  },
};
</script>
