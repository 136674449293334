<template>
  <PageBlock
    :block-title="blockTitle"
    :block-columns="blockColumns"
    :grid="[1, 5, 8, 11, 12]"
  >
    <slot></slot>
    <template v-slot:secondary>
      <div class="homepage-slider team-slider" v-if="currentSlide">
        <div class="homepage-slider--info-column team-slider--list anim">
          <p class="column-label">
            {{ blockColumns[1] }}
          </p>

          <ul class="team-list" role="list" aria-label="Team Members">
            <li v-for="(teamMember, index) in activeTeamMembers" :key="index">
              <button
                class="button button--outline"
                :class="{ active: index == sliderIndex }"
                @click="sliderIndex = index"
              >
                {{ teamMember.first_name }} {{ teamMember.last_name }}
              </button>
            </li>
          </ul>
        </div>

        <div class="homepage-slider--images team-slider--images">
          <div
            class="homepage-slider--images__container team-slider--images__container"
          >
            <div
              class="homepage-slider--images__container__inner team-slider--images__container__inner"
              :style="{
                transform:
                  'translateY(-' +
                  (sliderIndex / activeTeamMembers.length) * 100 +
                  '%)',
              }"
            >
              <div
                class="homepage-slider--images__container__inner__slide team-slider--images__container__inner__slide"
                v-for="(slide, index) in activeTeamMembers"
                :key="index"
                :class="{ current: index == sliderIndex }"
                :aria-hidden="index != sliderIndex"
                role="region"
                :aria-label="`${slide.first_name} ${slide.last_name}`"
              >
                <img
                  :src="
                    require(
                      `../assets/team/${slide.first_name.toLowerCase()}-${slide.last_name.toLowerCase()}.jpg`
                    )
                  "
                  :alt="`${slide.first_name} ${slide.last_name}`"
                />
              </div>
            </div>
          </div>
          <button
            class="homepage-slider--controls team-slider--controls homepage-slider--controls__up team-slider--controls__up"
            @click="sliderIndex = sliderIndex - 1"
            :disabled="sliderIndex == 0"
            aria-label="Previous Slide"
            :aria-disabled="sliderIndex == 0"
          >
            <span class="icon icon--arrow-up"></span>
          </button>
          <button
            class="homepage-slider--controls team-slider--controls homepage-slider--controls__down team-slider--controls__down"
            @click="sliderIndex = sliderIndex + 1"
            :disabled="sliderIndex == activeTeamMembers.length - 1"
            aria-label="Next Slide"
            :aria-disabled="sliderIndex == activeTeamMembers.length - 1"
          >
            <span class="icon icon--arrow-down"></span>
          </button>
        </div>
        <div class="team-slider--images__caption">
          <div class="anim anim2">
            <h3>{{ currentSlide.first_name }} {{ currentSlide.last_name }}</h3>
            <p>
              {{ currentSlide.title }}
            </p>
            <div class="social" v-if="currentSlide.social">
              <a
                v-for="(link, index) in currentSlide.social"
                :key="index"
                :href="link"
                role="button"
              >
                <button
                  class="button button--social"
                  tabindex="-1"
                  :aria-label="`${currentSlide.first_name} ${currentSlide.last_name} on LinkedIn`"
                >
                  <FontAwesomeIcon :icon="linkedinIcon" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </PageBlock>
</template>

<script>
import PageBlock from '@/components/PageBlock.vue';
import team from '@/assets/team.yaml';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

export default {
  name: 'TeamBlock',
  components: {
    PageBlock,
    FontAwesomeIcon,
  },
  props: {
    blockTitle: {
      type: String,
      default: 'The Team',
    },
    blockColumns: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    currentSlide() {
      return this.activeTeamMembers[this.sliderIndex];
    },
    activeTeamMembers() {
      return this._sortBy(this._filter(team, 'visibility'), 'position');
    },
  },
  data() {
    return {
      team: team || {},
      sliderIndex: 0,
      linkedinIcon: faLinkedinIn,
    };
  },
};
</script>
