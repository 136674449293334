<template>
  <figure
    :class="`gallery--website gallery--${item.youtube ? 'video' : 'image'}`"
  >
    <GridLines :columns="[1, 11, 12]" />
    <h3 v-if="item.title" class="wrapper">{{ item.title }}</h3>

    <div class="wrapper">
      <div class="inner-wrapper">
        <img
          :src="require(`../assets/case-study/${item.image}`)"
          :alt="`${item.alt}`"
          v-if="item.image"
        />
      </div>
    </div>
  </figure>
</template>

<script>
import GridLines from '@/components/GridLines.vue';
export default {
  name: 'GalleryWebsite',
  components: {
    GridLines,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
