var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageBlock',{attrs:{"block-title":_vm.blockTitle,"block-columns":_vm.blockColumns,"grid":[1, 5, 8, 11, 12]},scopedSlots:_vm._u([{key:"secondary",fn:function(){return [(_vm.currentSlide)?_c('div',{staticClass:"homepage-slider team-slider"},[_c('div',{staticClass:"homepage-slider--info-column team-slider--list anim"},[_c('p',{staticClass:"column-label"},[_vm._v(" "+_vm._s(_vm.blockColumns[1])+" ")]),_c('ul',{staticClass:"team-list",attrs:{"role":"list","aria-label":"Team Members"}},_vm._l((_vm.activeTeamMembers),function(teamMember,index){return _c('li',{key:index},[_c('button',{staticClass:"button button--outline",class:{ active: index == _vm.sliderIndex },on:{"click":function($event){_vm.sliderIndex = index}}},[_vm._v(" "+_vm._s(teamMember.first_name)+" "+_vm._s(teamMember.last_name)+" ")])])}),0)]),_c('div',{staticClass:"homepage-slider--images team-slider--images"},[_c('div',{staticClass:"homepage-slider--images__container team-slider--images__container"},[_c('div',{staticClass:"homepage-slider--images__container__inner team-slider--images__container__inner",style:({
              transform:
                'translateY(-' +
                (_vm.sliderIndex / _vm.activeTeamMembers.length) * 100 +
                '%)',
            })},_vm._l((_vm.activeTeamMembers),function(slide,index){return _c('div',{key:index,staticClass:"homepage-slider--images__container__inner__slide team-slider--images__container__inner__slide",class:{ current: index == _vm.sliderIndex },attrs:{"aria-hidden":index != _vm.sliderIndex,"role":"region","aria-label":`${slide.first_name} ${slide.last_name}`}},[_c('img',{attrs:{"src":require(
                    `../assets/team/${slide.first_name.toLowerCase()}-${slide.last_name.toLowerCase()}.jpg`
                  ),"alt":`${slide.first_name} ${slide.last_name}`}})])}),0)]),_c('button',{staticClass:"homepage-slider--controls team-slider--controls homepage-slider--controls__up team-slider--controls__up",attrs:{"disabled":_vm.sliderIndex == 0,"aria-label":"Previous Slide","aria-disabled":_vm.sliderIndex == 0},on:{"click":function($event){_vm.sliderIndex = _vm.sliderIndex - 1}}},[_c('span',{staticClass:"icon icon--arrow-up"})]),_c('button',{staticClass:"homepage-slider--controls team-slider--controls homepage-slider--controls__down team-slider--controls__down",attrs:{"disabled":_vm.sliderIndex == _vm.activeTeamMembers.length - 1,"aria-label":"Next Slide","aria-disabled":_vm.sliderIndex == _vm.activeTeamMembers.length - 1},on:{"click":function($event){_vm.sliderIndex = _vm.sliderIndex + 1}}},[_c('span',{staticClass:"icon icon--arrow-down"})])]),_c('div',{staticClass:"team-slider--images__caption"},[_c('div',{staticClass:"anim anim2"},[_c('h3',[_vm._v(_vm._s(_vm.currentSlide.first_name)+" "+_vm._s(_vm.currentSlide.last_name))]),_c('p',[_vm._v(" "+_vm._s(_vm.currentSlide.title)+" ")]),(_vm.currentSlide.social)?_c('div',{staticClass:"social"},_vm._l((_vm.currentSlide.social),function(link,index){return _c('a',{key:index,attrs:{"href":link,"role":"button"}},[_c('button',{staticClass:"button button--social",attrs:{"tabindex":"-1","aria-label":`${_vm.currentSlide.first_name} ${_vm.currentSlide.last_name} on LinkedIn`}},[_c('FontAwesomeIcon',{attrs:{"icon":_vm.linkedinIcon}})],1)])}),0):_vm._e()])])]):_vm._e()]},proxy:true}])},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }