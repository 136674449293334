<template>
  <main id="pagenotfound" class="reveal-content">
    <section class="pagenotfound page page--content page--content__very-dark">
      <GridLines :columns="grid" />
      <div class="wrapper">
        <div class="page--backbtn">
          <div class="horizontal-text">
            <router-link to="/" class="back-button"> Home </router-link>
          </div>
        </div>
        <div class="page--fullwidth">
          <h1>Page not found</h1>
          Whoops! Looks like you've stumbled upon a page that doesn't exist.
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import GridLines from '@/components/GridLines.vue';

export default {
  name: 'PageNotFound',
  components: {
    GridLines,
  },
  mounted() {
    setInterval(() => {
      const rand = Math.floor(Math.random() * 9) + 2;
      this.grid = [1, rand, 11, 12];
    }, 100);
  },
  data() {
    return {
      grid: [1, 11, 12],
    };
  },
  metaInfo: {
    title: 'Whoops!',
  },
};
</script>
