<template>
  <section class="page page--content">
    <GridLines :columns="grid" />
    <div class="wrapper">
      <header
        class="page--title"
        ref="fitme-container"
        v-if="blockTitle !== 'BackBtn'"
      >
        <div class="anim">
          <div class="horizontal-text">
            <h1 ref="fitme">{{ blockTitle }}</h1>
          </div>
        </div>
      </header>
      <div class="page--backbtn" v-else>
        <div class="anim">
          <div class="horizontal-text">
            <router-link :to="backTo ? backTo : '/'" class="back-button">
              Back
            </router-link>
          </div>
        </div>
      </div>
      <div class="page--main">
        <div class="anim">
          <p class="column-label" v-if="blockColumns.length > 0">
            <template v-if="blockColumnsLink.length > 0 && blockColumnsLink[0]">
              <router-link :to="blockColumnsLink[0]">
                {{ blockColumns[0] }}
              </router-link>
            </template>
            <template v-else>
              {{ blockColumns[0] }}
            </template>
          </p>
          <slot></slot>
        </div>
      </div>
      <div class="page--secondary" v-if="$slots.secondary">
        <slot name="secondary"></slot>
      </div>
    </div>
    <div class="page--end" v-if="$slots.end">
      <slot name="end"></slot>
    </div>
  </section>
</template>

<script>
import GridLines from '@/components/GridLines.vue';
//import 'FitText.js/fittext.js';

export default {
  name: 'PageBlock',
  components: {
    GridLines,
  },
  mounted() {
    if (!this.$refs['fitme'] || !this.$refs['fitme-container']) {
      return;
    }
    const fitMeSize = parseInt(
      window.getComputedStyle(this.$refs['fitme']).width
    );
    const fitMeContainerSize =
      parseInt(window.getComputedStyle(this.$refs['fitme-container']).height) -
      100;
    const textOverflowing = fitMeSize > fitMeContainerSize;

    if (textOverflowing) {
      const ratio = fitMeContainerSize / fitMeSize;
      this.$refs['fitme'].style.transform =
        `scale(${Math.floor(ratio * 100) / 100})`;
    }
  },
  props: {
    blockTitle: {
      type: String,
      default: '',
    },
    blockColumns: {
      type: Array,
      default: () => [],
    },
    blockColumnsLink: {
      type: Array,
      default: () => [],
    },
    grid: {
      type: Array,
      default: () => [1, 5, 10, 11, 12],
    },
    backTo: {
      type: String,
      default: '/',
    },
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
  data() {
    return {};
  },
};
</script>
